<template>
  <div>我是活动管理页面</div>
</template>

<script>
export default {
    name: 'Mall',
    data() {
        return{}
    }
}
</script>
<style scoped>
</style>